<template>
    <BaseButton
        :element="NuxtLink"
        :to="url"
        :target="link.target"
        :aria-label="link.ariaLabel"
        :class="
            appearance === 'link' ? 'base-button--link' : ''
        "
    >
        <slot name="default">{{ link.text }}</slot>
    </BaseButton>
</template>

<script setup>
import { HOME_URI } from '~/enums/page-uri';
const NuxtLink = resolveComponent('NuxtLink');

const props = defineProps({
    link: {
        type: Object,
        required: true,
        default: () => {}
    },
    appearance: {
        type: String,
        default: 'link',
        validator: (value) => ['link', 'button'].includes(value)
    }
});

/**
 * Given a variety of link types, return the URL to link to. Translate it, and replace any __home__ placeholders
*/
const url = computed(() => {
    if (!props.link) {
        return undefined;
    }
    const localePath = useLocalePath();

    let uri = null;
    if (props.link?.element?.uri) {
        uri = props.link.element.uri;
    }

    else if (props.link?.url) {
        // URL is absolute, so return it unchanged
        return props.link.url;
    }

    if (uri === HOME_URI) {
        uri = '/';
    }

    if (!uri) {
        return undefined;
    }

    // Strip any leading slashes
    uri = uri.replace(/^\/+/g, '');

    return localePath({
        path: `/${uri}`
    });
});
</script>
